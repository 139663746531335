<template>
  <div>
    <div class="topNavBox">
      <div class="topbox clearfix">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <p class="fl">隱私協作</p>
      </div>
    </div>

    <div class="agreeWrap">
      <div class="container-bd" v-if="flag == 'zh_TW'">
        <div class="c-left">
          <h3 class="page-title page-title-split">嗨文小説隱私政策</h3>
          <div class="mod mod-base news">
            <div class="bd">
              <p>
                以下為隱私政策正文，我們建議您認真、完整地閱讀本隱私政策。
              </p>
              <h4>一、以下為隱私政策正文，我們建議您認真、完整地閱讀本隱私政策。</h4>
              <ol class="list decimal-list">
                <li>
                  1.1 保護用戶個人信息是的一項基本原則。我們致力於提升信息處理透明度，增強您對信息管理的便捷性，保障您的信息及通信安全。嚴格遵守法律法規，遵循以下隱私保護原則，為您提供更加安全、可靠的服務：
                  <ul class="list">
                    <li>
                      （1）安全可靠：我們竭盡全力通過合理有效的信息安全技術及管理流程，防止您的信息泄露、損毀、丟失；
                    </li>
                    <li>
                      （2）保護通信祕密：我們嚴格遵照法律法規，保護您的通信祕密，為您提供安全的通信服務
                    </li>
                    <li>
                      （3）合理必要：為了向您和其他用戶提供更好的服務，我們僅收集必要的信息；
                    </li>
                    <li>
                      （4）清晰透明：我們努力使用簡明易懂的表述，向您介紹隱私政策，以便您清晰地瞭解我們的信息處理方式；
                    </li>
                  </ul>
                </li>
                <li>
                  1.2 您在註冊賬號或使用本服務的過程中，可能需要填寫一些必要的信息（詳見第二點，我們可能會收集的信息）。。一般而言，我們僅為實現目的所必需的最短時間保留您的個人信息。當我們的產品或服務發生停止運營的情形時，我們將採取通知或公告的形式通知您，並在合理的期限內刪除或匿名化處理您的個人信息；
                </li>
                <li>
                  1.3 一般情況下，您可隨時瀏覽、修改自己提交的信息，但出於安全性和身份識別（如號碼申訴服務）的考慮，您可能無法修改註冊時提供的初始註冊信息及其他驗證信息。
                </li>
                <li>
                  1.4 我們非常重視對未成年人個人信息的保護。根據相關法律法規的規定，若您是18周歲以下的未成年人，在使用我們的的服務前，應事先取得您的家長或法定監護人的同意。 若您是未成年人的監護人，當您對您所監護的未成年人的個人信息有相關疑問時，請通過網站中的聯系方式與我們聯系。
                </li>
                <li>
                  1.5 未經您的同意，我們不會向嗨文小說以外的任何公司、組織和個人披露您的個人信息，保證不對外公開或向第三方披露或提供用戶註冊資料及用戶在使用網絡服務時存儲在本軟件、服務器或數據庫的非公開內容和信息，但下列情況除外：
                  <ul class="list">
                    <li>（1）事先獲得用戶的明確授權；</li>
                    <li>（2）根據有關的法律法規要求；</li>
                    <li>
                      （3）按照相關政府主管部門和司法機關的要求；
                    </li>
                    <li>
                      （4）為維護社會公眾的利益；
                    </li>
                    <li>
                      （5）為執行相關服務協議或為保護我們的客戶、我們或我們的關聯公司、其他用戶或僱員的人身財產安全或其他合法權益所合理必需的用途。
                    </li>
                  </ul>
                </li>
                <li>
                  1.6 因用戶使用第三方服務或者設備，可能導致用戶信息通過其他方式透露給第三方，用戶需自行了解第三方對用戶信息保護的相關條款，嗨文小說不承擔由此產生的風險。上述情形包括：用戶在移動設備上使用第三方應用程序；因第三方應用程序收集的用戶信息可能發送至第三方進行處理。
                </li>
              </ol>
              <h4>二、【個人信息收集的範圍與方式】</h4>
              <p>
                我們根據合法、正當、必要的原則，僅收集實現產品功能所必要的信息。我們可能會收集、儲存和使用下列與您有關的信息。對本協議的同意將被視為同意我們收集您的個人信息，並在法律規定及本協議約定的範圍內進行使用。
              </p>
              <ol class="list decimal-list">
                <li>
                  2.1 我們收集以下各類信息：
                  <ul class="textIndet10">
                    <li>
                      2.1.1 賬號服務所需信息：
                      <ul class="list textIndet20">
                        <li>
                          （1） 您在註冊賬戶時填寫的信息：手機號碼*/電子郵箱地址*、密碼*；
                        </li>
                        <li>
                          （2） 我們可能會獲得您在使用第三方合作夥伴的賬號（Facebook、Google）登錄時，第三方向我們披露的信息，包括：用戶名、頭像。用於與本軟件賬號綁定，使您可以直接登錄並使用本軟件。對於這部分內容，請您仔細閱讀第三方合作夥伴服務的用戶協議或隱私政策；
                        </li>
                        <li>
                          （3） 您在編輯資料時填寫/選擇的信息：頭像、暱稱；
                        </li>
                        <li>
                          （4） 個人賬戶資料顯示：頭像、暱稱、應用內虛擬幣餘額*、已訂作品數量；
                        </li>
                        <li>
                          （5） 賬戶安全：我們需要收集您的一些信息來保障您的賬號安全、判斷您的賬戶及交易風險，包括如下信息：手機廠商、系統版本、系統語言、產品版本號、交易信息*；一旦我們檢測出存在或疑似存在賬號安全風險時，我們會使用相關信息進行安全驗證與風險排除，確保我們向您提供的產品和服務的安全性，以用來保障您的權益不受侵害。
                        </li>
                      </ul>
                    </li>
                    <li>
                      2.1.2 產品或服務所需信息
                      <ul class="list textIndet20">
                        <li>
                          （1） 文字閱讀功能：為了能夠更好的為您提供小說閱讀服務，提升閱讀體驗、優化小說展示效果，我們需要收集如下信息：設備型號、設備品牌、設備唯一標識符、操作系統和應用程序版本及來源渠道、網絡類型、日誌信息*（操作日誌、服務日誌、開發日誌、閃退日誌）；
                        </li>
                        <li>
                          （2） 搜索功能：當您使用搜索功能時，我們需要收集您搜索的字詞、瀏覽記錄，以提供給您更好的搜索結果、同時改進我們的產品服務；
                        </li>
                        <li>
                          （3） 評論信息發布功能：當您使用評論功能時，我們將收集您主動提供的圖文信息；
                        </li>
                        <li>
                          （4） 客服/用戶反饋功能：當您通過客服進行諮詢、賬號申訴功能時，我們可能需要收集您的賬號信息以驗證您的身份，也有可能收集您反饋意見和建議時填寫的手機號碼*、郵箱；<br>
                        </li>
                        <li>
                          * 我們的部分服務可能需要您提供特定的個人敏感信息（本隱私政策中標註*）來實現特定功能。若您選擇不提供該類信息，則可能無法正常使用服務中的特定功能，但不影響您使用服務中的其他功能。若您主動提供您的個人敏感信息，即表示您同意我們按本政策所述目的和方式來處理您的個人敏感信息
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  2.2 根據用戶行為及產品特點，我們可能訪問用戶手機上的以下內容：
                  <ul class="textIndet10">
                    <li>
                      2.2.1 對於使用嗨文小說的用戶，您在使用我們向您提供的相關產品時，為提供、處理、維護、改善、開發我們的商品和/或提供給您的服務之目的，該產品可能獲取您終端設備的如下權限：
                      <ul class="textIndet20">
                        <li>
                          （1）用戶下載圖書時，因需將圖書數據存入SD卡內而寫入/刪除內部存儲；
                        </li>
                        <li>
                          （2）用戶閱讀已下載圖書時，因需讀取圖書數據而讀取內部存儲；
                        </li>
                        <li>
                          （3）為確認下載圖書數據存放方式需判斷用戶是否插入SD卡而使用掛載文件系統權限；
                        </li>
                        <li>
                          （4）為計算存儲空間的空閑區域而使用獲取應用大小權限；
                        </li>
                        <li>
                          （5）為使用本軟件聯網功能而使用訪問網絡權限；
                        </li>
                        <li>
                          （6）為判斷用戶當前網絡狀態以便提示用戶而獲取用戶網絡狀態；
                        </li>
                        <li>
                          （7）提示用戶打開網絡而使用改變網絡狀態權限；
                        </li>
                        <li>
                          （8）用戶進行下載圖書操作時，當用戶當前網絡是2G/3G/4G時，為節約用戶流量提示其打開wifi網絡而使用獲取、改變wifi狀態權限；
                        </li>
                        <li>
                          （9）為用戶調節手機亮度而讀寫系統設置項；
                        </li>
                        <li>
                          （10）為保證用戶在閱讀的圖書更新時能第一時間獲得通知而允許程序開機自動運行、允許程序在手機屏幕關閉後後臺進程仍然運行；
                        </li>
                        <li>
                          （11）為發生異常情況時重啟本軟件而使用結束系統任務權限；
                        </li>
                        <li>
                          （12）為在手機桌面創建快捷方式而使用排序系統任務；
                        </li>
                        <li>
                          （13）為在Android 7.1系統及以上實現ShortCut快捷方式功能，需要創建桌面快捷方式權限；
                        </li>
                        <li>
                          （14）為給用戶手機發送圖書更新通知時手機震動而使用允許振動權限；
                        </li>
                        <li>
                          （15）為幫助用戶解決本軟件發生異常時的問題而讀取系統日誌；
                        </li>
                        <li>
                          （16）為監測用戶將圖書分享到其他應用功能是否正常而使用允許程序獲取當前或最近運行的應用權限；
                        </li>
                        <li>
                          （17）為實現懸浮窗功能而使用顯示系統窗口權限；
                        </li>
                        <li>
                          （18）為使用戶在全屏閱讀時，閱讀頁面顯示電池電量信息而使用電池狀態權限；
                        </li>
                        <li>
                          （19）當用戶在設置閱讀界面常亮，需要使用保持屏幕常亮權限，使系統不會因為用戶長時間不操作而關閉屏幕；
                        </li>
                        <li>
                          （20）為了允許應用出現閃退後清楚本身進程重啟，需要殺死後臺進程功能；
                        </li>
                      </ul>
                    </li>
                    <li>
                      2.2.2 第三方SDK接入服務所需開通的權限
                      <p>
                        我們的產品和服務可能包括第三方的產品和服務，以及第三方網站的鏈接。當您使用這些產品或服務時，也可能收集您的信息。在第三方向您提供產品和服務時，可能會獲取到以下權限：
                      </p>
                      <ul class="textIndet20">
                        <li>
                          （1）友盟移動數據統計SDK需通過用戶當前的位置*來進行相關決策，需獲取粗略位置*及位置*權限；
                        </li>
                        <li>
                          （2）為接受阿裏雲發來的消息，需獲取阿裏雲推送權限；
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ol>
              <h4>三、【個人信息的使用方式】</h4>
              <ol class="list decimal-list">
                <li>
                  3.1 我們可能如何使用信息
                  <ul class="textIndet10">
                    <li>
                      3.1.1用戶同意我們基於下列原因需要使用用戶的信息資源：
                      <ul class="textIndet20">
                        <li>
                          （1）向您提供核心閱讀服務；
                        </li>
                        <li>
                          （2）安全保障。我們會將您的信息用於身份驗證、安全防範、反詐騙監測、存檔備份、客戶的安全服務中；
                        </li>
                        <li>
                          （3）產品的開發和優化。當我們的系統發生故障時，我們會記錄和分析系統故障時產生的信息，優化我們的服務
                        </li>
                        <li>
                          （4）使我們更加了解您如何接入和使用我們的服務，從而針對性地回應您的個性化需求，包括個性化推薦書籍、個性化推送；
                        </li>
                        <li>
                          （5）執行軟件，進行軟件認證或軟件升級；
                        </li>
                        <li>
                          （6）讓您參與有關我們產品和服務的調查；
                        </li>
                        <li>
                          （7）向您推薦您可能感興趣的廣告、資訊、評估，進一步改善我們的廣告投放和其他促銷及推廣活動的效果；
                        </li>
                        <li>
                          （8）在用戶同意的情況下，將根據用戶信息所作出的統計數據、行為分析非關聯用戶身份識別的信息提供給合作單位，用於呈現合作效果；
                        </li>
                        <li>
                          （9）為用戶推送通知及嗨文小說提供的服務信息；
                        </li>
                        <li>
                          （10）修改全局系統設置；更改屏幕顯示方向；使用模擬地點來源進行測試；編輯短信或彩信。
                        </li>
                      </ul>
                    </li>
                    <li>
                      3.1.2涉及個人信息使用的功能列表：
                      <ul class="textIndet20">
                        <li>
                          （1） 基礎的賬號服務：使用您的手機號碼、您使用的暱稱及密碼用戶創建賬號，繼而使用需要平臺賬號才能開通的服務：評論、分享、簽到、點贊、投守護票、送紅包、參加活動，展示用戶獲得的公開榮譽；
                        </li>
                        <li>
                          （2） 當用戶參與站內舉辦的活動時，我們需要用戶的個人信息：暱稱/頭像用於抽獎結果、粉絲排行榜及其他公示內容的展示。
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  3.2 為了讓您有更好的體驗、改善我們的服務或您同意的其他用途，在符合相關法律法規的前提下，我們可能將通過某一項服務所收集的信息用於我們的其他服務。在您使用我們的一項服務時所收集的信息，可能在另一服務中用於向您提供特定內容，或向您展示與您相關的、非普遍推送的信息。如果我們在相關服務中提供了相應選項，您也可以授權我們將該服務所提供和儲存的信息用於我們的其他服務。
                </li>
                <li>
                  3.3 為了確保服務的安全，幫助我們更好地瞭解我們應用程序的運行情況，我們可能記錄相關信息，您使用應用程序的頻率、故障信息、總體使用情況、性能數據以及應用程序的來源。我們不會將我們存儲在分析軟件中的信息與您在應用程序中提供的個人身份信息相結合。
                </li>
                <li>
                  3.4 在您使用我們的產品或服務時，我們可能通過放置安全的Cookie及相關技術收集您的信息，包括：您的登錄信息、瀏覽信息、偏好設置。您也可以通過瀏覽器設置管理Cookie。但請註意，如果停用Cookie，您可能無法享受最佳的服務體驗，某些服務也可能無法正常使用。Cookie 對提升用戶的網絡使用體驗十分重要，我們使用Cookie一般出於以下目的：
                  <ul class="textIndet10">
                    <li>
                      （1）優化登錄體驗： Cookie可以在您進行註冊、登錄時，通過存儲賬號信息，幫您填入您最後登入的賬號名稱、簡化您重復操作的步驟；
                    </li>
                    <li>
                      （2）安全：Cookie可幫助我們保障數據和服務的安全性，排查針對我們的產品和服務的作弊、黑客、欺詐行為；
                    </li>
                  </ul>
                </li>
                <li>
                  3.5 信息的分享和披露
                  <ul class="textIndet10">
                    <li>
                      3.5.1 在法律法規規定的情況下，或經您事先明確同意或手動選擇，我們可能與第三方分享您的個人信息；
                    </li>
                    <li>
                      3.5.2 僅為實現外部處理的目的，我們可能會與第三方合作夥伴（第三方服務供應商、承包商、代理、廣告合作夥伴、應用開發者，代表我們發出電子郵件或推送通知的通訊服務提供商、為我們提供位置*服務的地圖服務供應商）（他們可能並非位於您所在的法域）分享您的個人信息，讓他們按照我們的說明、隱私協議以及其他相關的保密和安全措施來為我們處理上述信息，如我們與上述第三方分享您的信息，我們將會採用加密、匿名化處理等手段保障您的信息安全，並用於以下用途：
                      <ul class="textIndet20">
                        <li>
                          （1）向您提供我們的服務；
                        </li>
                        <li>
                          （2）實現"我們如何使用收集的信息"部分所述目的；
                        </li>
                        <li>
                          （3）履行我們在《用戶服務協議》中的義務和行使我們的權利；
                        </li>
                        <li>
                          （4）理解、維護和改善我們的服務。
                        </li>
                      </ul>
                      如我們與上述第三方分享您的信息，我們將會採用加密、匿名化處理手段保障您的信息安全。
                    </li>
                    <li>
                      3.5.3信息的轉讓
                    </li>
                    <li>
                      我們不會對嗨文小說以外的公司、組織個人轉讓用戶的個人信息，但以下情況除外:
                      <ul class="textIndet20">
                        <li>
                          （1）在獲取明確同意的情況下轉讓：獲得您的明確同意後，我們會向其他方轉讓您的個人信息；
                        </li>
                        <li>
                          （2）隨著我們業務的持續發展，當發生合並、收購、資產轉讓交易導致向第三方分享您的個人信息時，我們將通過推送通知、公告形式告知您相關情形，按照法律法規及不低於本協議所要求的標準繼續保護或要求新的管理者繼續保護您的個人信息。
                        </li>
                      </ul>
                    </li>
                    <li>
                      3.5.4 我們會將所收集到的信息用於大數據分析。我們將收集到的信息用於分析形成不包含任何個人信息的行業洞察報告。我們可能對外公開並與我們的合作夥伴分享經統計加工後不含身份識別內容的信息，用於瞭解用戶如何使用我們服務或讓公眾瞭解我們服務的總體使用趨勢。
                    </li>
                    <li>
                      3.5.5 我們可能基於以下目的披露您的個人信息：
                      <ul class="textIndet20">
                        <li>
                          （1）遵守適用的法律法規等有關規定；
                        </li>
                        <li>
                          （2） 遵守法院判決、裁定或其他法律程序的規定；
                        </li>
                        <li>
                          （3） 遵守相關政府機關或其他法定授權組織的要求；
                        </li>
                        <li>
                          （4） 我們有理由確信需要遵守法律法規等有關規定；
                        </li>
                        <li>
                          （5） 為執行相關服務協議或本協議、維護社會公共利益，為保護我們的客戶、我們或我們的關聯公司、其他用戶或僱員的人身財產安全或其他合法權益合理且必要的用途。
                        </li>
                      </ul>
                    </li>
                    <li>
                      3.5.6 共享、轉讓、公開披露個人信息涉及類型
                      <ul class="textIndet20">
                        <li>
                          （1）網頁對外共享、轉讓、公開披露的個人信息的類型：設備號、網絡地域、充值/消費金額*、閱讀書籍、發表評論/想法、書籍評分、創建的書單、讀過書籍數量、關註作者/用戶、收藏書籍/專題/書單；
                        </li>
                        <ul>
                          （2）App對外共享、轉讓、公開披露的個人信息的類型：廣告服務加密提供通過該渠道下載用戶設備號、渠道、版本、平臺、閱讀時長、閱讀書籍分類、付費金額*。
                        </ul>
                      </ul>
                    </li>
                    <li>
                      3.5.7 我們已知曉對外共享、轉讓、公開披露個人信息所承擔的相應法律責任，並將採取合理措施保障個人信息安全。
                    </li>
                  </ul>
                </li>
              </ol>
              <h4>四、【知識產權】</h4>
              <ul>
                <li>
                  4.1 嗨文小說的一切知識產權，以及與嗨文小說相關的所有信息內容，包括但不限於文字表述及其組合、圖標、圖飾、圖像、圖表、色彩、界面設計、版面框架、有關數據、附加程序、印刷材料或電子文檔等均為嗨文小說所有，受著作權法和國際著作權條約以及其他知識產權法律法規的保護。
                </li>
                <li>
                  4.2 未經嗨文小說書面同意，用戶不得以任何營利性或非營利性的目的自行實施、利用、轉讓或許可任何第三方實施、利用、轉讓上述知識產權，嗨文小說保留追究上述未經許可行為的權利。
                </li>
                <li>
                  4.3 禁止反向工程、反向編譯和反向匯編：用戶不得對本軟件產品進行反向工程（Reverse Engineer）、反向編譯（Decompile）或反向匯編（Disassemble），同時不得改動編譯在程序文件內部的任何資源。除法律、法規明文規定允許上述活動外，用戶必須遵守此協議限製。
                </li>
                <li>
                  4.4 組件分割：本軟件產品是作為一個整體產品而被授予許可使用,用戶不得將各個部分分開用於任何目的。
                </li>
                <li>
                  4.5 個別授權：如需進行商業性的銷售、復製、分發，包括但不限於軟件銷售、預裝、捆綁等，必須獲得嗨文小說的書面授權和許可。
                </li>
                <li>
                  4.6 保留權利：本協議未明示授權的其他一切權利仍歸嗨文小說所有，用戶使用其他權利時必須獲得嗨文小說的書面同意。
                </li>
                <li>
                  4.7 嗨文小說提供的書籍內容僅代表作者自己的立場和觀點，與嗨文小說無關，由作者本人承擔一切法律責任。
                </li>
              </ul>
              <h4>五、您的權利</h4>
              <p class="mt10">
                按照中國相關的法律、法規、標準，以及其他國家、地區的通行做法，我們保障您對自己的個人信息行使以下權利：
              </p>
              <ul>
                <li>
                  5.1 查詢、復制、或更正、補充您的個人信息
                </li>
                <p class="mt10">
                  當您完成賬號註冊並進行合理和必要的身份驗證後，您可以隨時登錄自己的賬號查看、復制或修改賬號設置和信息。如果您發現信息有錯誤、不準確或不完整的，您可以登錄賬號，在您的賬號設置中修改您的賬號信息以更正、補充或者通過本政策列明的聯系方式提出異議並請求我們進行更正、補充，除非法律法規另有規定。
                </p>
                <p class="mt10">
                  如您希望查看、復制或修改您的其他個人信息，或在行使上述權利過程中遇到困難，您可以通過本政策列明的聯系方式聯系我們，我們將在15個工作日內完成核查和處理，但法律法規另有規定的或本政策聲明另有約定的除外。
                </p>
                <li>
                  5.2 刪除您的個人信息
                  <p class="mt10">在以下情形中，您可以向我們提出刪除個人信息的請求：</p>
                  <ul class="list">
                    <li>
                      （1）如果我們處理個人信息的行為違反法律法規；
                    </li>
                    <li>
                      （2）如果我們收集、使用您的個人信息，卻未徵得您的同意；
                    </li>
                    <li>
                      （3）如果我們處理個人信息的行為違反了與您的約定
                    </li>
                    <li>
                      （4）如果您不再使用我們的產品或服務，或您註銷了帳號；
                    </li>
                    <li>
                      （5）如果我們不再為您提供產品或服務。
                    </li>
                  </ul>
                  <p class="mt10">
                    如您希望刪除您的其他個人信息，或在行使上述權利過程中遇到困難，您可以通過本政策列明的方式聯系我們，我們將在15個工作日內完成核查和處理，但法律法規另有規定的或本政策聲明另有約定的除外。
                  </p>
                  <p class="mt10">
                    請註意，法律、行政法規或其他規範性文件規定的保存期限尚未屆滿，或者刪除個人信息從技術上難以實現的，我們將停止除存儲和採取必要的安全保護措施之外的處理，直到可以刪除或實現匿名化。
                  </p>
                </li>
                <li>
                  5.3 註銷您的賬戶
                  <p class="mt10">
                    您隨時可註銷此前註冊的帳戶，但您應當向本站網絡提供帳號相應的註冊信息，具體流程可在此頁面瞭解（https://www.blackrockread.com/#/logout）。
                  </p>
                  <p class="mt10">
                    在註銷賬戶之後，我們將停止為您提供產品或服務，並依據法律法規相關要求通過匿名化等方式處理您的個人信息，或刪除與您賬號相關的個人信息，但法律法規或監管機構對用戶信息存儲時間另有規定的除外。
                  </p>
                </li>
                <li>
                  5.4 改變您授權同意的範圍或撤銷您的授權同意
                  <p class="mt10">
                    請您理解，每個業務功能往往需要開啟部分權限並收集必要的個人信息才能得以實現，對於您個人信息的收集、使用以及設備權限的管理。您可以通過在我們具體產品/服務中刪除信息、 更改隱私設置以及在瀏覽器或操作系統中關閉設備功能等方式改變同意範圍或撤回您的授權。
                  </p>
                  <p class="mt10">
                    您可以通過您的手機系統權限撤回您授予的設備權限，您也可以通過該路徑開啟相關設備權限。
                  </p>
                  <p class="mt10">
                    請您理解，當您執行上述操作後，我們將無法繼續為您提供撤回同意或授權所對應的服務，但不會影響此前基於您的授權而開展的個人信息處理。
                  </p>
                  <P class="mt10">
                    如果您希望更改或撤回其他已經您授權的同意，您可以通過產品實際界面提供的撤銷方式或本政策列明的聯系方式與我們取得聯系並行使您的相關權利。
                  </P>
                  <p class="mt10">
                    如果該等授權同意並非使用服務所必需，您可以通過我們的客服與我們聯系，對部分個人信息改變或撤銷您的授權同意。我們會在15個工作日內答復您的請求。
                  </p>
                  <p class="mt10">
                    當您撤銷授權同意後，我們將不再處理相應的個人信息。但您撤銷授權同意的決定，不會影響此前基於您的授權而開展的個人信息處理。
                  </p>
                </li>
                <li>
                  5.5 響應您的上述請求
                  <p class="mt10">
                    為保障安全，您可能需要提供書面請求，或以其他方式證明您的身份。我們可能會先要求您驗證自己的身份，然後再處理您的請求。
                  </p>
                  <p class="mt10">
                    對於您合理的請求，我們原則上不收取費用，但對多次重復、超出合理限度的請求，我們將視情況收取壹定成本費用。對於那些無端重復、需要過多技術手段（例如，需要開發新系統或從根本上改變現行慣例）、 給他人合法權益帶來風險或者非常不切實際（例如，涉及備份磁帶上存放的信息）的請求，我們可能會予以拒絕。
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ol class="list decimal-list">
          <li>hwkefu@gmail.com</li>
          <li>Copyright © 2021</li>
          <li>嗨文小説</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        flag: 'zh_TW '
      }
    },
    mounted () {
      if (this.$route.query.lang) {
        this.flag = this.$route.query.lang
      } else {
        this.flag = 'zh_TW'
      }
    },
    methods: {
      seeBack() {
        this.$router.go(-1);
      },
    }
  };
</script>

<style lang="stylus" scoped>
  .agreeWrap
    color #636778
    padding 10px 24px 30px
    font-size 26px
    letter-spacing 2px
    line-height 42px
    h6
      font-size 30px
      margin 15px 0
    li
      margin 10px 0
  .textIndet10
    text-indent 10px
  .textIndet20
    text-indent 20px
</style>
